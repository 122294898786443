<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75 10L1.53349 6.14019C1.2784 5.83408 1.15085 5.68102 1.15085 5.5C1.15085 5.31898 1.2784 5.16592 1.53349 4.85982L4.75 1"
      :stroke="iconStyle"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 10L6.78349 6.14019C6.5284 5.83408 6.40085 5.68102 6.40085 5.5C6.40085 5.31898 6.5284 5.16592 6.78349 4.85982L10 1"
      :stroke="iconStyle"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '11',
    },
    width: {
      type: String,
      default: '11',
    },
    color: {
      type: String,
      default: 'primary-gray-700',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return `var(--${this.color})`
    },
  },
}
</script>
